<template>
  <section class="c_result">
    <h2 class="c_result_ttl">コンテンツ一覧</h2>
    <div class="c_result_filter">
      <label class="c_result_select">
        <select v-model="c_sort">
          <option value="new">新着順</option>
          <option value="high-rate">最高評価</option>
          <option value="max-review">レビューの多い順</option>
        </select>
      </label>
      <div class="c_result_srch">
        <label class="c_result_srch">
          <input
            type="text"
            placeholder="キーワード検索"
            v-model="v_keyword"
            @keyup.prevent.enter="doSearch"
          />
        </label>
        <button type="submit">
          <span class="icon-search"></span>
        </button>
      </div>
    </div>
    <div class="c_result_list">
      <div class="c_tool_item" v-for="c in contents" :key="c.id">
        <div class="c_tool_item_cnt">
          <!--
          <p class="c_tool_item_ttl" :class="{ '-new': isNew(c.from_field) }">
            <em>{{ c.name }}</em>
          </p>
-->
          <p class="c_tool_item_ttl">
            <em>{{ c.name }}</em>
          </p>
          <p class="c_tool_item_author">
            <em>{{ c.company }}</em>
          </p>
          <p class="c_tool_item_disc">{{ c.description }}</p>
          <div class="c_tool_item_eval">
            <div class="c_tool_item_eval mr-1">
              <div class="c_tool_item_eval_star">
                <star-rating
                  :value="c.review_rating_average"
                  :width="13"
                  :height="13"
                ></star-rating>
              </div>
              <span class="c_tool_item_eval_count">
                ({{ c.review_rating_count }})
              </span>
            </div>
            <!--
            <div class="c_tool_item_eval c_tool_item_eval_count">
              <div>公開日:</div>
              <time class="mr-1">{{ c.from_field | formatDateHyphen }}</time>
              <div class="c_tool_item_eval" v-if="!c.to_indefinite">
                <div>期限:</div>
                <time class="mr-1">{{ c.to | formatDateHyphen }}</time>
              </div>
            </div>
-->
          </div>
          <!--
          <p class="c_tool_item_info" v-if="c.type === 16">
            <span>ver {{ c.tool.version }}</span> （<time>{{
              c.tool.created_at | formatDateDot
            }}</time>）
          </p>
          <ul class="c_tool_item_tags">
            <li v-for="tag in c.tags" :key="tag.id">
              <a @click.stop="searchTag(tag.text)">{{ tag.text }}</a>
            </li>
          </ul>
          -->
        </div>
        <div class="c_tool_item_link">
          <a class="c_tool_item_link" @click="clickContents(c)">詳細を見る</a>
        </div>
      </div>
      <div :class="{ c_load: loading }"></div>
    </div>
    <div class="c_result_btn">
      <button
        class="c_btn -large"
        type="button"
        v-if="paginator.page < paginator.max_page"
        @click="getNext"
      >
        さらに表示
      </button>
    </div>
    <div
      v-if="!loading && contents.length === 0"
      style="text-align: center; padding:20px;"
    >
      口コミ可能なコンテンツはありません。
    </div>
    <!-- アクセス制限 -->
    <modal
      name="unauthorized"
      adaptive
      height="auto"
      width="85%"
      :maxWidth="640"
    >
      <section id="unauthorized" class="c_popup">
        <h2 class="c_popup_ttl">閲覧制限</h2>
        <p class="c_popup_lead">
          このコンテンツを閲覧する権限がありません。
          <br />閲覧制限を解除するには、所属の管理者経由で警察庁にご連絡ください。
        </p>
        <div class="c_popup_btns">
          <button
            class="c_btn"
            type="button"
            @click="$modal.hide('unauthorized')"
          >
            閉じる
          </button>
        </div>
      </section>
    </modal>
  </section>
</template>

<script>
import moment from "moment";
import StarRating from "@/components/StarRating";
import { jc3LearningDomain } from "@/../config.json";
export default {
  components: {
    StarRating,
  },
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,
      contents: [],
      paginator: { page: 1, max_page: 1 },
      loading: false,
      v_keyword: "",
    };
  },
  props: ["qs"],
  computed: {
    c_sort: {
      get() {
        return this.qs.sort;
      },
      set(value) {
        this.updateCondition(value, this.qs.keyword);
      },
    },
  },
  created() {
    this.fetch();
  },
  watch: {
    $route: "fetch",
  },
  methods: {
    updateCondition(sort, keyword) {
      this.paginator = Object.assign({}, this.paginator, {
        page: 1,
        max_page: 1,
      });
      this.contents.splice(0, this.contents.length);
      this.$router
        .push({
          name: "ReviewExLearning",
          query: { sort: sort, keyword: keyword },
        })
        .catch(() => {});
    },
    fetch() {
      this.$store.dispatch("updateSearchQuery", this.qs);
      this.v_keyword = this.qs.keyword;
      this.loading = true;

      this.$authAxios
        .get(`/api/review/ex-learning/courses`, {
          params: {
            page: this.paginator.page,
            sort: this.qs.sort,
            keyword: this.qs.keyword,
          },
        })
        .then((response) => {
          this.contents = this.contents.concat(response.data.results);
          this.paginator = response.data.paginator;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    doSearch() {
      this.updateCondition(this.qs.sort, this.v_keyword);
    },
    isNew(date) {
      // 3か月以内に作成されてればNEW
      return moment(date).isAfter(moment().subtract(3, "months"));
    },
    getNext() {
      this.paginator.page += 1;
      this.fetch();
    },
    clickContents(contents) {
      console.log(contents);
      this.$router.push({
        name: "ReviewExLearningDetail",
        params: { id: contents.id },
      });
    },
    searchTag(tag) {
      this.$router.push({ name: "Search", query: { tag: tag } });
    },
  },
};
</script>
