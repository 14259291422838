<template>
  <div class="-review">
    <div class="c_main -pad-btm">
      <header class="c_main_hd">
        <div class="inner">
          <nav class="c_pnkz">
            <ul class="c_pnkz_list">
              <li>
                <a class="c_pointer" @click="$router.push({ name: 'Home' })"
                  >ホーム</a
                >
              </li>
              <li>外部教育口コミ</li>
            </ul>
          </nav>
          <h1 class="c_main_ttl">外部教育口コミ</h1>
        </div>
      </header>
      <div class="c_main_body">
        <div class="inner">
          <section-ex-cource-list :qs="qs"></section-ex-cource-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionExCourceList from "@/components/SectionExCourceList";

export default {
  components: {
    SectionExCourceList,
  },
  props: {
    qs: {
      type: Object,
      default: function() {
        return {
          sort: "new",
          keyword: "",
        };
      },
    },
  },
};
</script>
